.message-container {
  padding: 1em;
  margin: 0.2em;
}

.message-left {
  font-size: 1.2em;
  border-style: solid;
  border-width: 1px;
  border-color: #292929;
  padding: 0.4em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 10px;
  float: left;
}

.message-right {
  font-size: 1.2em;
  border-style: solid;
  border-width: 1px;
  border-color: #292929;
  padding: 0.4em;
  padding-left: 0.5em;
  border-radius: 10px;

  background-color: white;
  color: black;

  float: right;
}

.msg-input-container {
  position: fixed;
  width: 100%;
  background-color: red;
  bottom: 0;
  height: 60px;
}

.msg-input-field {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 50px;
  z-index: 99;
  background: #fafafa;
  border: none;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  color: #666;
  font-weight: 400;
}
